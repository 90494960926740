import React from 'react'
import {Helmet} from 'react-helmet-async'
import {Navigate, useNavigate} from 'react-router-dom'
import {useInvalidate, usePost} from '~/api/query'
import {Button} from '~/components/ui/button'
import {Formik} from 'formik'
import {Form, FormInput, FormStatus} from '~/components/ui/form'
import {toFormikValidate} from 'zod-formik-adapter'
import {z} from 'zod'
import {Card} from '~/components/ui/card'
import {PageBack} from '~/components/PageBack'
import {buildUrl} from '~/router'
import {useProfile} from '~/hooks/useProfile'
import Loading from '~/components/Loading'
import {assertNever} from '~/lib/utils'
import {GoogleOAuthButton} from '~/lib/GoogleOAuthButton'
import {Dialog, DialogContent, DialogTitle} from '~/components/ui/dialog'
import {useToast} from '~/hooks/use-toast'

const LoginSchema = z.object({
    email: z.string(),
    password: z.string(),
})

const ManualLoginForm: React.FC<{onSuccess: () => void}> = ({onSuccess}) => {
    const loginMutation = usePost('/identity/login')
    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
            }}
            validate={toFormikValidate(LoginSchema)}
            onSubmit={async (values, {setSubmitting, setStatus}) => {
                setSubmitting(true)
                const response = await loginMutation.mutateAsync({
                    email: values.email,
                    password: values.password,
                })
                setSubmitting(false)
                if (response.type === 'user') {
                    onSuccess()
                    return
                } else if (response.type === 'login_error') {
                    const codeToMessage: Record<typeof response.code, string> = {
                        already_authenticated: 'You are already authenticated',
                        invalid_credentials: 'Invalid username or password',
                    }
                    setStatus(codeToMessage[response.code])
                    return
                }
                assertNever(response)
            }}
        >
            {({isSubmitting}) => (
                <Form className="w-full max-w-72">
                    <FormInput name="email" label="Email" type="email" />
                    <FormInput name="password" label="Password" type="password" />
                    <FormStatus />
                    <Button type="submit" disabled={isSubmitting} className="self-center">
                        Login
                    </Button>
                </Form>
            )}
        </Formik>
    )
}

const OAuthLoginWithGoogle = ({onSuccess}: {onSuccess: () => void}) => {
    const getLoginUrlMutation = usePost('/identity/google-login-url')
    const oAuthCallbackMutation = usePost('/identity/login/oauth-callback')

    const doLogin = async (code: string) => {
        await oAuthCallbackMutation.mutateAsync({code})
        window.history.replaceState({}, document.title, window.location.pathname) // Remove search params from the url
        onSuccess()
    }
    React.useEffect(() => {
        // If this is the first page visit after returning from oAuth, use the code to authenticate
        const url = new URL(window.location.href)
        const code = url.searchParams.get('code')
        if (code) {
            doLogin(code)
        }
    }, [])

    const redirectToOAuth = async () => {
        const response = await getLoginUrlMutation.mutateAsync({
            referrer_domain: window.location.origin,
            referrer_callback_path: window.location.pathname,
        })
        window.location.href = response.redirect_url
    }

    const isLoading = getLoginUrlMutation.isPending || oAuthCallbackMutation.isPending

    return (
        <div className="flex flex-col justify-start">
            <GoogleOAuthButton disabled={isLoading} onClick={redirectToOAuth} />
            {isLoading ? (
                <Loading loadingText="Logging in" className="mt-2 justify-center text-sm" />
            ) : null}
        </div>
    )
}

const Login: React.FC<{method: 'password' | 'google'; onSuccess: () => void}> = ({
    method,
    onSuccess,
}) => {
    const profile = useProfile()

    if (profile.type == 'user') {
        return null
    }

    return (
        <div className="flex flex-1 flex-col items-center justify-center text-center">
            <img src="/logo-rounded.svg" className="mx-auto my-8 h-32 w-32" alt="Green Chip Data" />
            <p className="mb-8">To continue, log in to Green Chip Data</p>
            <div className="min-h-20">
                {method == 'password' ? (
                    <ManualLoginForm onSuccess={onSuccess} />
                ) : method === 'google' ? (
                    <OAuthLoginWithGoogle onSuccess={onSuccess} />
                ) : (
                    assertNever(method)
                )}
            </div>
        </div>
    )
}

export const LoginModal: React.FC<{
    onSuccess: () => void
    isOpen: boolean
    setIsOpen: (open: boolean) => void
}> = ({onSuccess, isOpen, setIsOpen}) => {
    const invalidate = useInvalidate()

    const successCallback = () => {
        invalidate('/identity/profile')
        invalidate('/watchlist/')
        setIsOpen(false)
        onSuccess()
    }

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogContent>
                <DialogTitle className="sr-only">Login</DialogTitle>
                <Login method="google" onSuccess={successCallback} />
            </DialogContent>
        </Dialog>
    )
}

const StandaloneLoginPage: React.FC<{method: 'password' | 'google'}> = ({method}) => {
    const invalidate = useInvalidate()
    const navigate = useNavigate()
    const profile = useProfile()
    const {toast} = useToast()

    const onSuccess = () => {
        invalidate('/identity/profile')
        invalidate('/watchlist/')
        navigate(buildUrl('/'))

        toast({
            title: "You're logged in!",
        })
    }

    if (profile.type == 'user') {
        return <Navigate to={buildUrl('/')} replace />
    }

    return (
        <div className="flex flex-1 flex-col">
            <Helmet>
                <title>Login | Green Chip Data</title>
                {/* Login with password is a private page, google's the only public method */}
                {method === 'password' && <meta name="robots" content="noindex, nofollow" />}
            </Helmet>
            <PageBack />
            <Card className="mx-auto w-96 px-4 py-12">
                <Login method={method} onSuccess={onSuccess} />
            </Card>
        </div>
    )
}

// This component that sits on all pages,
// listens for when the search params are an oAuth callback,
// and bounces the user to the referring page
export const OAuthLoginListener: React.FC = () => {
    const navigate = useNavigate()
    React.useEffect(() => {
        const url = new URL(window.location.href)
        const state = url.searchParams.get('state')
        if (state) {
            navigate(state + url.search, {replace: true})
        }
    }, [])

    return <></>
}

export default StandaloneLoginPage
